import React from 'react';
import { Link } from 'react-router-dom';
import { Hero } from "../../components/header";

import ImageNWDLogo from "../../assets/images/North-Wessex-Downs-Logo.webp";
import ImageSWFSLogo from "../../assets/images/south-west-england-fibreshed.png";

function Support() {
    const pageTitle = 'Support';
    document.title = global.AppName + ' - ' + pageTitle;
    return (
        <>
            <Hero title={"Support Received"} subtitle={"To help build the tannery"}></Hero>

            {/* start main */}
            <div className="main_bg">
                <div className="wrap">
                    <div className="main" id="support">
                        <h2>Thank you</h2>
                        {/* <h3>some things you should know about us</h3> */}
                        <section>
                            <p>
                                Setting up a tannery from scratch is not easy, particularly one for cattle hides. One of the things I have loved about getting into the 
                                leather industry is the openness that everyone has shown me. 
                            </p>
                            <p>
                                This wouldn't have been possible without support from a number of organisations and people, be it money, time, knowledge, support or other 
                                contributions. I am humbled by their generosity, kindness and confidence in the project:
                            </p>
                            <p>
                                <div className='grantaward'>
                                    <div>
                                        <Link to='https://www.northwessexdowns.org.uk/' title='North Wessex Downs National Landscape' target='_blank' className='linkImage'>
                                            <img src={ImageNWDLogo} alt='North Wessex Downs National Landscape Logo'></img>
                                        </Link>
                                    </div>
                                    <div>
                                        A hugely significant "Farming in a Protected Landscape (FiPL)" grant to build the tannery building (including installing3-phase electrics, 
                                        building a reedbed for managing waste, water harvesting and more) was given by 
                                        the <Link to='https://www.northwessexdowns.org.uk/' title='North Wessex Downs National Landscape' target='_blank'>North Wessex Downs National Landscape</Link> 
                                    </div>
                                    <div>
                                        <Link to='https://southwestenglandfibreshed.co.uk/' title='Southwest Fibershed' target='_blank' className='linkImage'>
                                            <img src={ImageSWFSLogo} alt='Southwest Fibreshed Logo'></img>
                                        </Link>
                                    </div>
                                    <div>
                                        A grant from the <Link to='https://southwestenglandfibreshed.co.uk/' title='Southwest Fibershed' target='_blank'>SW Fibreshed</Link> funded 
                                        the initial two day workshop to check the viability of setting up the tannery. Without it we wouldn't have got the ball rolling.
                                    </div>
                                </div>
                            </p>
                            <ul>
                                <li>
                                    Katie (<Link to='https://www.loopyewes.co.uk' title='Loopy Ewes' target='_blank'>Loopy Ewes</Link>) - I couldn't have a more supportive 
                                    wife. When I said I would love to set up a tannery the response I got was "Go on then"!
                                </li>
                                <li>Jacki Rodikis - You couldn't want for a more supportive mother-in-law</li>
                                <li>
                                    <Link to='https://www.churchillfellowship.org' title='Churchill Fellowship' target='_blank'>Churchill Fellowship</Link> - Travel 
                                    scholarship that funded trips to see Matt at Traditional Tanners in Oregon, Lineapelle in Milan and the tannery region of Italy. Details of 
                                    the travel scholarship can be found on the <Link to='/churchill-fellowship' title='Churchill Fellowship Blog'>Churchill Fellowship Blog</Link>
                                </li>
                                <li>
                                    Matt Richards (<Link to='https://www.braintan.com' title='Matt Richards - Traditional Tanners' target='_blank'>Traditional Tanners</Link>) - 
                                    It is thank you to Matt that I made my first piece of leather and was hooked, so it is all his fault! If you have any interest in leather you 
                                    need to do his <Link to='https://braintan.com/product-category/kitchen-table-tannery/' title='online tanning courses' target='_blank'>online zoom courses</Link>, 
                                    it is where I started. I was also lucky enough to spent 3 weeks at Matt's micro tannery, as part of 
                                    my <Link to='../blog/US' title='US Churchill Fellowship'>Churchill Fellowship travels</Link>. His knowledge and willingness to share was 
                                    second to none. I walked away from the trip knowing I could set a tannery up and how to do it, and walked away with a new friend.
                                </li>
                                <li>
                                    Barry Knight - One of the good ones. There is probably no one in the UK with a greater knowledge of veg tanning. His generosity with his 
                                    time and his kindness has made the success of the tannery possible. I couldn't have asked for a better mentor.
                                </li>
                                <li>
                                    Karl Flower (<Link to='https://www.authenticae.co.uk/' title='Authenticae' target='_blank'>Authenticae</Link>) - Karl helped turn a mad 
                                    idea into a possibility and set me off on this path by starting with the basics of how to make leather.
                                </li>
                                <li>
                                    Kerry Senor (<Link to='https://www.leatheruk.org' title='Leather UK' target='_blank'>Leather UK</Link>) - Leather UK have help promote 
                                    the tannery and, through the British Leather Industry Development Trust, enable Barry to be a mentor for three years.
                                </li>
                                <li>
                                    Richard Daniels - Richard was one of the first to bring reed beds to the tannery industry to process the waste. His knowledge in this area 
                                    is second to none and he generosity shared everything he knew to get our reed beds set up. 
                                </li>
                                <li>Julian Shaw - Expert courier who produced some of the finest leather at John Whites and Sons.</li>
                                <li>Jon Thornes - I haven't only had support in building the tannery and making leather, Jon has advised on the business side of the tannery</li>
                                <li>
                                    There are numerous other people who have also given their support to the project, including letters of support for funding applications, not  
                                    least of all Jimmy Woodrow (<Link to='https://pastureforlife.org/' title='Pasture for Life' target='_blank'>Pasture for Life</Link>), 
                                    Emma Hague (<Link to='https://southwestenglandfibreshed.co.uk/' title='Southwest Fibershed' target='_blank'>The Southwest Fibreshed</Link>, 
                                    Sheila Cooke (<Link to='https://www.3lm.network/' title='3LM' target='_blank'>3LM</Link>) and many more.

                                </li>
                            </ul>
                        </section>
                        <div className="clear"></div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Support;