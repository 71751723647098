import React from 'react';
import { Hero } from "../../components/header";
import { Link } from 'react-router-dom';
import './template.css';

import USPost from "../blog/categories/us";
import LineapellePost from "../blog/categories/lineapelle";

import ImageLogo from "../../assets/images/churchill-fellowship-logo.webp";

function App() {
    const pageTitle = 'Churchill Fellowship';
    document.title = global.AppName + ' - ' + pageTitle;
    const logo = (
        <Link to='https://www.churchillfellowship.org/' title='Churchill Fellowship' target='_blank' className='image'>
            <img src={ImageLogo} alt='Churchill Fellowship logo'></img>
        </Link>
    );
    return (
        <>
            <Hero title={"Churchill Fellowship"} subtitle={"Travel Scholarship"} logo={logo}></Hero>

            {/* start main */}
            <div className="main_bg">
                <div className="wrap">
                    <div className="main" id="blogs">
                        <div>
                            <h2>Travel Blog</h2>
                            <p>
                                In 2023 I was awarded a <Link to='https://www.churchillfellowship.org/' title='Churchill Fellowship' target='_blank'>Churchill Fellowship</Link> travel 
                                scholarship. The scholarship was to fund international travel to build his knowledge of tanning and to visit other tanneries abroad.
                            </p>

                            <p>
                                The travel was split into four parts:
                            </p>
                        </div>
                        <USPost mode={"summary"} />
                        <LineapellePost mode={"summary"} />
                        <section>                            
                            <h3>Part 3</h3>
                            <h4>2 weeks visiting some of the remaining veg tanneries in Europe.</h4>
                            <p>
                                Then in November I am spending a week visiting the tanneries of 
                                the <Link to='https://www.pellealvegetale.it/en/consortium/' title='Genuine Italian Vegetable-Tanned Leather Consortium' target='_blank'>Genuine Italian Vegetable-Tanned Leather Consortium</Link> in 
                                Tuscany, Italy. Including:
                            </p>
                            <ul>
                                <li><Link to='https://www.conceriailponte.it' title='Conceria Il Ponte' target='_blank'>Conceria Il Ponte</Link></li>
                                <li><Link to='https://www.lostivale.it' title='CLo Stivale' target='_blank'>Lo Stivale</Link></li>
                            </ul>
                            <p>as well as the tannery:</p>
                            <ul>
                                <li><Link to='https://www.conceriamaryam.it' title='Maryam S.r.l.' target='_blank'>Maryam S.r.l.</Link></li>
                            </ul>
                            <p>and various tannery machine manufacturers.</p>
                        </section>
                        <section>                            
                            <h3>Part 4</h3>
                            <p>
                                Finally, I am also organising visits to the German tanneries <Link to='https://kilger.de/en/' title='Kilger Tannery' target='_blank'>Kilger</Link> and <Link to='https://www.lederfabrik-renz.de/' title='August Renz' target='_blank'>August Renz</Link>.
                            </p>
                        </section>

                        <div className="clear"></div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default App;