import React from 'react';
import BlogPost from "../../../components/blogpost";
import { Link } from 'react-router-dom';
import '../../home/template.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

import ImageBeamhouseDrum from "../../../assets/images/blog/beamhouse-drum.webp";

function App({ mode="full" }) {
    function getHTML (mode)
    {
        switch (mode) {
            case "summary":
                return (
                    <section>
                    <h3><Link to='/blog/tannery-build' title='Tannery build Blog'>Tannery Build</Link></h3>
                    <h4>The evolution of the tannery build project</h4>
                    <p>
                        It is no quick, easy task building a tannery from scratch, even with the <Link to='/support' title='Help and support received'>support I have received</Link>. 
                    </p>
                    <p>
                        This blog covers the journey over a couple of years.
                    </p>
                    <Link to='/blog/tannery-build' title='Tannery build Blog' className='linkImage'><img src={ImageBeamhouseDrum} alt='Tannery Build Blog'></img></Link>
                    <Link to='/blog/tannery-build' title='Tannery build Blog' className='linkArrow'><span>View</span> <FontAwesomeIcon icon={faArrowRight} /></Link>
                </section>
                )
            case "full":
            default:
                return (
                    <section>
                    <BlogPost id={"21"}/>
                    <BlogPost id={"22"}/>
                    <BlogPost id={"23"}/>
                </section>
                )
        }
    }
        
    return (
        getHTML(mode)
    )
}

export default App;