import React from 'react';
import { Hero } from "../../components/header";
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import '../home/template.css';

import USPosts from "../blog/categories/us";
import LineapellePosts from "../blog/categories/lineapelle";
import TanneryBuildPosts from "../blog/categories/tannerybuild";

function App({}) {
    const { category } = useParams();
    var tripTitle = category;
    var postClass = "blogposts"

    if (category === undefined) {
        tripTitle = "summary";
        postClass = "blogs";
    }

    const pageTitle = category + " Blog";
    document.title = global.AppName + ' - ' + pageTitle;

    function selectTrip(tripTitle){
        switch(tripTitle.toLowerCase()) {
            case "us":
                return (
                    <USPosts mode={"full"} />
                )
            case "lineapelle":
                return (
                    <LineapellePosts mode={"full"} />
                )
            case "tannery-build":
                return (
                    <TanneryBuildPosts mode={"full"} />
                )
            case "all":
                return (
                    <>
                        <USPosts mode={"full"} />
                        <LineapellePosts mode={"full"} />
                        <TanneryBuildPosts mode={"full"} />
                    </>
                )
            case "summary":
            default:
                return (
                    <>
                        <USPosts mode={"summary"} />
                        <LineapellePosts mode={"summary"} />
                        <TanneryBuildPosts mode={"summary"} />
                    </>
                )
        }
    }

    return (
        <>
            <Hero title={"Blog"}></Hero>
            {/* start main */}
            <div className="main_bg">
                <div className="wrap">
                    <div className="main" id={postClass}>
                        <div>
                            <h2>{category} Blog</h2>
                        </div>
                        {selectTrip(tripTitle)}
                        <div className="clear"></div>
                        <Link to='javascript:history.back()' title='Go back' className='linkArrow'><FontAwesomeIcon icon={faArrowLeft} /> <span>Go back</span></Link>
                    </div>
                </div>
            </div>
        </>
    );
}

export default App;