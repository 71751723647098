import React from 'react';
import BlogPost from "../../../components/blogpost";
import { Link } from 'react-router-dom';
import '../../home/template.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

import ImageJamesMatt from "../../../assets/images/blog/james-and-matt.webp";

function App({ mode="full" }) {
    function getHTML (mode)
    {
        switch (mode) {
            case "summary":
                return (
                    <section>
                        <h3><Link to='/blog/US' title='US Blog'>Part 1</Link></h3>
                        <h4>3 week tanning course for setting up a micro-tannery with Matt Richards - 
                            <Link to='https://braintan.com/' title='Traditional Tanners' target='_blank'>Traditional Tanners</Link> (Oregon, US)</h4> 
                        <p>
                            Matt is an expert traditional tanner with decades of experience. He is one of the few traditional veg tannery in the world with a micro-tannery.
                        </p>
                        <Link to='/blog/US' title='US Blog' className='linkImage'><img src={ImageJamesMatt} alt='US Blog'></img></Link>
                        <Link to='/blog/US' title='US Blog' className='linkArrow'><span>View</span> <FontAwesomeIcon icon={faArrowRight} /></Link>
                    </section>
                )
            case "full":
            default:
                return (
                    <section>
                        <BlogPost id={"01"}/>
                        <BlogPost id={"02"}/>
                        <BlogPost id={"03"}/>
                        <BlogPost id={"04"}/>
                        <BlogPost id={"05"}/>
                        <BlogPost id={"06"}/>
                        <BlogPost id={"07"}/>
                        <BlogPost id={"08"}/>
                        <BlogPost id={"09"}/>
                        <BlogPost id={"10"}/>
                        <BlogPost id={"11"}/>
                        <BlogPost id={"12"}/>
                        <BlogPost id={"13"}/>
                        <BlogPost id={"14"}/>
                        <BlogPost id={"15"}/>
                        <BlogPost id={"16"}/>
                        <BlogPost id={"17"}/>
                        <BlogPost id={"18"}/>
                        <BlogPost id={"19"}/>
                    </section>
                )
        }
    }
        
    return (
        getHTML(mode)
    )
}

export default App;